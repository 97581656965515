import { Language } from '@genteras/ui-lib';

export const langauges = [
  // { id: Language.en_US, locale: 'en-US', title: 'English', shortTitle: 'EN' },
  { id: Language.zh_TW, locale: 'zh-TW', title: '中文繁體', shortTitle: '繁' },
  { id: Language.zh_CN, locale: 'zh-CN', title: '中文簡体', shortTitle: '簡' },
  // { id: Language.th_TH, locale: 'th-TH', title: 'ไทย', shortTitle: 'ไทย' },
  // {
  //   id: Language.id_ID,
  //   locale: 'id-ID',
  //   title: 'Bahasa Indonesia',
  //   shortTitle: 'ID',
  // },
  // {
  //   id: Language.vi_VN,
  //   locale: 'vi-VN',
  //   title: 'Tiếng Việt',
  //   shortTitle: 'VI',
  // },
  // {
  //   id: Language.my_MM,
  //   title: 'မြန်မာဘာသာ',
  //   shortTitle: 'MY',
  //   locale: 'my-MM',
  // },
  // {
  //   id: Language.ko_KR,
  //   title: '한국어',
  //   shortTitle: 'KO',
  //   locale: 'ko-KR',
  // },
  // {
  //   id: Language.ja_JP,
  //   title: '日本語',
  //   shortTitle: 'jp',
  //   locale: 'ja-JP',
  // },
  // {
  //   id: Language.mn_MN,
  //   title: 'Монгол',
  //   shortTitle: 'mn',
  //   locale: 'mn-MN',
  // },
];
