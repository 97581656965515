import CaesarFavicon from '../public/images/logo/caesar-favicon.png';
import CaesarLogoCompact from '../public/images/logo/caesar-s.png';
import CaesarLogo from '../public/images/logo/caesar.png';

export enum Sites {
  DEFAULT = 'play.xxxx.com',
  LOCAL = 'localhost',
}

export type SiteConfig = {
  siteName_i18n: {
    [key: string]: string;
  };
  siteName?: string;
  logoHeader: string;
  logoCompact: string;
  favicon: string;
  systemIcon: string;
};

const sitesConfig: Map<Sites, SiteConfig> = new Map([
  [
    Sites.DEFAULT,
    {
      siteName_i18n: {
        ['zh-TW']: '凱撒',
        ['zh-CN']: '凯撒',
        ['en']: 'CAESAR',
      },
      logoHeader: CaesarLogo.src,
      logoCompact: CaesarLogoCompact.src,
      favicon: CaesarFavicon.src,
      systemIcon: '',
    },
  ],
  [
    Sites.LOCAL,
    {
      siteName_i18n: {
        ['zh-TW']: '凱撒',
        ['zh-CN']: '凯撒',
        ['en']: 'CAESAR',
      },
      logoHeader: CaesarLogo.src,
      logoCompact: CaesarLogoCompact.src,
      favicon: CaesarFavicon.src,
      systemIcon: '',
    },
  ],
]);

export default {
  sitesConfig,
};
