import type {
  BetRecordConfig,
  CommonConfig,
  DisclaimerConfig,
  GameResultConfig,
  HomeConfig,
  MemberReportConfig,
  SettingConfig,
} from './configType';

export const commonConfig: CommonConfig = {
  i18nNamespaces: ['common'],
} as const;

export const homeConfig: HomeConfig = {
  i18nNamespaces: ['common'],
} as const;

export const betRecordConfig: BetRecordConfig = {
  i18nNamespaces: ['common', 'betRecord'],
} as const;

export const disclaimerConfig: DisclaimerConfig = {
  i18nNamespaces: ['common', 'disclaimer'],
} as const;

export const memberReportConfig: MemberReportConfig = {
  i18nNamespaces: ['common', 'memberReport', 'gameResult'],
} as const;

export const settingConfig: SettingConfig = {
  i18nNamespaces: ['common'],
} as const;

export const gameResultConfig: GameResultConfig = {
  i18nNamespaces: ['gameResult'],
} as const;
