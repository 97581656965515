import { Modal } from 'antd';
import { useState } from 'react';
import LoginForm from '@/components/profile/LoginForm';

export const useLoginModal = () => {
  const [visible, setVisible] = useState(false);

  const openModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const LoginModal = () => {
    return (
      <Modal
        // css={popupModalCss}
        centered
        title=" "
        visible={visible}
        onCancel={closeModal}
        footer={null}
        maskClosable={false}
        destroyOnClose
        className="login-modal"
      >
        <LoginForm name={'loginFormBtn'} onSubmitSuccess={closeModal} />
      </Modal>
    );
  };

  return {
    Modal: LoginModal,
    open: openModal,
    close: closeModal,
  };
};
