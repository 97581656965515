import styled from '@emotion/styled';
import { Button } from 'antd';

export const StyledButton = styled(Button)({
  background: 'var(--Yellow)',
  minHeight: '40px',
  border: 'none',
  color: 'var(--Black)',
  fontWeight: 'bold',
  ['&:hover']: {
    background: 'var(--Gold)',
    border: 'none',
  },
});
