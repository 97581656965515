import { FormattedDateTime } from '@genteras/ui-lib';
import { List } from 'antd';
import type { FC } from 'react';
import {
  listCss,
  StyledListItem,
  dateCss,
} from '@/components/notification-center/NotificationCenter';
import useAnnouncements from '@/lib/hooks/useAnnouncements';

type Props = {
  pageIndex: number;
};

export const AnnouncementList: FC<Props> = ({ pageIndex }) => {
  const { announcements } = useAnnouncements(pageIndex);
  return (
    <List
      css={listCss}
      dataSource={announcements.data}
      renderItem={(item) => (
        <StyledListItem>
          {item.message}
          <div>
            <FormattedDateTime css={dateCss} value={item.createdAt} />
          </div>
        </StyledListItem>
      )}
    />
  );
};
