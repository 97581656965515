import { GlobalOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import type { MenuProps } from 'antd';
import { Dropdown, Menu } from 'antd';
import { setCookie } from 'cookies-next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { langauges } from '@/lib/i18n/Language';

const LanguageDd = styled(Dropdown)({
  display: 'flex',
  alignItems: 'center',
  height: 30,
  borderRadius: '15px 15px',
  width: 70,
  border: '1px solid var(--White)',
});
const LanguageCtn = styled.span({
  cursor: 'pointer',
  color: 'var(--White)',
  height: 30,
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'center',
});
const GlobalOutlinedIcon = styled(GlobalOutlined)({
  color: 'var(--White)',
  fontSize: 25,
  marginRight: 5,
});

export const LanguageDropdown: FC = () => {
  const router = useRouter();
  const { locale, pathname } = router;

  const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
    setCookie('NEXT_LOCALE', key);
  };

  const MenuItems = langauges.map((locale) => ({
    key: locale.id,
    id: locale.id,
    label: (
      <Link href={pathname} locale={locale.locale}>
        <a>{locale.title}</a>
      </Link>
    ),
  }));

  const languageMenu = <Menu items={MenuItems} onClick={handleMenuClick} />;

  return (
    <LanguageDd overlay={languageMenu} placement="bottomRight">
      <LanguageCtn>
        <GlobalOutlinedIcon />
        {langauges.find((x) => x.id === locale)?.shortTitle}
      </LanguageCtn>
    </LanguageDd>
  );
};
