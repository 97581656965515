import { useRouter } from 'next/router';
import { useEffect } from 'react';
import useSWR from 'swr';
import { urls } from '../../constant/urls';
import type { User } from '../api/user';
import fetcher from '../utils/fetcher';

const useUser = () => {
  const router = useRouter();
  const { data: user, error } = useSWR('/players/me', fetcher);

  useEffect(() => {
    if (
      user?.username &&
      !user.passwordLastReset &&
      !router.pathname.includes('reset-password')
    ) {
      router.push(urls.resetPassword);
    }
  }, [Boolean(user)]);

  return {
    user: (user ?? {}) as User,
    isLoading: !error && !user,
    isError: error,
  };
};

export default useUser;
