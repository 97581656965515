import type { CSSInterpolation } from '@emotion/serialize/types';

export const popupModalCss: CSSInterpolation = {
  ['.ant-modal-content']: {
    borderRadius: 15,
  },
  ['.ant-modal-header']: {
    borderRadius: 15,
    borderBottom: 0,
  },
};

export enum AccountStatus {
  FROZEN = 'frozen',
}
