import type { CSSInterpolation } from '@emotion/serialize/types';

export const searchFormCss: CSSInterpolation = {
  gap: 16,
  ['.ant-form-item.dateRangePicker']: {
    maxWidth: '100%',
    minWidth: 300,
  },
};

export const popupFormCss: CSSInterpolation = {
  ['.ant-form-item']: {
    alignContent: 'center',
    marginBottom: 0,
    marginTop: 10,
  },
};
