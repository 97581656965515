import styled from '@emotion/styled';
import { Input } from 'antd';

export const StyledInput = styled(Input)`
  border-radius: 10px;
  border: transparent;
`;

export const StyledPassword = styled(Input.Password)`
  border-radius: 10px;
  border: transparent;
`;
