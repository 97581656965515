import { LeftOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { cssMedia } from '@genteras/core-lib/utils/css-utils';
import { getMainSiteUrl } from '@genteras/ui-lib/base';
import { Modal, Space } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { urls } from 'constant/urls';
import Logo from '../../../public/images/logo/caesar-s.png';
import useUser from '../../lib/hooks/useUser';
import FrozenAccountAlert from '../common/FrozenAccountAlert';
import FrozenAlert from '../common/FrozenAlert';
import { LanguageDropdown } from '../common/LanguageDropdown';
import { AccountStatus, popupModalCss } from '../common/Modal';
import { UserAmount } from '../common/UserAmount';
import { UserMenuDropdown } from '../common/UserMenuDropdown';
import { NotificationCenter } from '../notification-center/NotificationCenter';

const LightHeader = styled(Header)({
  background: 'transparent',
  padding: '10px 20px',
  height: 80,
  [cssMedia.md]: {
    height: 130,
  },
});

const HeaderContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: 'auto auto auto',
  padding: 0,
  [cssMedia.md]: {
    padding: '20px 0 0',
  },
});

const HeaderLeft = styled.div({
  height: '64px',
  background: 'transparent',
  display: 'flex',
  alignItems: 'center',
  width: 55,
  [cssMedia.sm]: {
    width: 220,
  },
  [cssMedia.md]: {
    width: 280,
  },
});
const HeaderMiddle = styled.div({
  margin: 5,
});

const HeaderRight = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: '64px',
  background: 'transparent',
});

const HeaderRightSpace = styled(Space)({
  [cssMedia.xs]: {
    gap: '3px !important',
  },
});

const BackMainLink = styled.a({
  padding: '0 10px 0 0',
  color: 'var(--White)',
  transform: 'scaleY(1.5)',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  height: 30,
});

let isFirstLoad = false;

export const MainHeader: FC = () => {
  const { user, isLoading } = useUser();
  const router = useRouter();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showOnce, setShowOnce] = useState(false);

  useEffect(() => {
    if (isLoading) return;
    if (
      user?.username &&
      !user.passwordLastReset &&
      !router.pathname.includes('reset-password')
    ) {
      router.push(urls.resetPassword);
    }
  }, [user, isLoading, router]);

  if (
    !isModalVisible &&
    user.overallStatus == AccountStatus.FROZEN &&
    !showOnce &&
    !isFirstLoad
  ) {
    setIsModalVisible(true);
    setShowOnce(true);
    setTimeout(() => {
      isFirstLoad = true;
    }, 100);
  }

  if (showOnce && user.overallStatus != AccountStatus.FROZEN) {
    setShowOnce(false);
  }

  const handleClickLogo = () => {
    router.push(urls.play);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const KSLogoImage = styled.img({
    cursor: 'pointer',
    width: '449px',
    height: '100px',
    ['@media (max-width: 767px)']: {
      width: '250px',
      height: '50px',
    },
    ['@media (max-width: 1199px)']: {
      width: '350px',
      height: '95px',
    },
    [cssMedia.xs]: {
      width: '200px',
      height: '50px',
    },
  });

  return (
    <>
      <LightHeader>
        <HeaderContainer>
          <HeaderLeft>
            {user ? null : (
              <BackMainLink href={getMainSiteUrl(router.locale)}>
                <LeftOutlined />
              </BackMainLink>
            )}
            <KSLogoImage
              src={Logo.src}
              alt={'logo'}
              onClick={handleClickLogo}
            ></KSLogoImage>
          </HeaderLeft>
          <HeaderMiddle>
            {user.overallStatus == AccountStatus.FROZEN ? (
              <FrozenAccountAlert />
            ) : null}
          </HeaderMiddle>
          <HeaderRight>
            <HeaderRightSpace>
              {isLoading || !user.username ? null : (
                <div
                  css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 15,
                    alignItems: 'center',
                  }}
                >
                  <NotificationCenter />
                  <UserMenuDropdown />
                  <UserAmount />
                </div>
              )}
              <LanguageDropdown />
            </HeaderRightSpace>
          </HeaderRight>
        </HeaderContainer>
      </LightHeader>
      <Modal
        css={popupModalCss}
        centered
        width={800}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
      >
        <FrozenAlert />
      </Modal>
    </>
  );
};
