export enum Language {
  en_US = 'en-US',
  zh_CN = 'zh-CN',
  zh_TW = 'zh-TW',
  th_TH = 'th-TH',
  id_ID = 'id-ID',
  vi_VN = 'vi-VN',
  my_MM = 'my-MM',
  ms_MY = 'ms-MY',
  ko_KR = 'ko-KR',
  ja_JP = 'ja-JP',
  mn_MN = 'mn-MN',
}

export const DEFAULT_LANGUAGE = Language.en_US;
