'use client';

import type { FC, ReactNode } from 'react';
import { createContext, useContext } from 'react';
import { useLoginModal } from '../hooks/useLoginModal';

type Props = {
  children: ReactNode;
};

type GlobalModalContext = {
  showLoginModal: () => void;
};

const initalState: GlobalModalContext = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  showLoginModal: () => {},
};

const GlobalModalContext = createContext(initalState);
export const useGlobalModalContext = () => useContext(GlobalModalContext);

export const GlobalModal: FC<Props> = ({ children }) => {
  const { Modal, open: showLoginModal } = useLoginModal();
  return (
    <GlobalModalContext.Provider value={{ showLoginModal }}>
      {children}
      <Modal />
    </GlobalModalContext.Provider>
  );
};
