import { datadogRum } from '@datadog/browser-rum';
import { notification } from 'antd';

interface NotifyError {
  message?: string;
  description?: string;
  // No axios and next-i18n in this package
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  e?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t?: any;
  isSubagent?: boolean;
}

interface NotifySuccess {
  message?: string;
  description?: string;
}

export enum SpecialWarnings {
  CONFIRM_EMAIL = 'CONFIRM_EMAIL',
}

enum ApiError {
  HAS_UNRESOLVED_BETS = 'HAS_UNRESOLVED_BETS',

  DUPLICATED_USERNAME = 'DUPLICATED_USERNAME',
  DUPLICATED_EMAIL = 'DUPLICATED_EMAIL',
  DB_ERROR = 'DB_ERROR',

  INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE',

  REQUIRE_HIGHER_LEVEL_TO_ACTIVATE = 'REQUIRE_HIGHER_LEVEL_TO_ACTIVATE',
  MAX_AGENT_DEPTH = 'MAX_AGENT_DEPTH',

  USER_NOT_FOUND = 'USER_NOT_FOUND',

  BET_LIMIT_IN_USE = 'BET_LIMIT_IN_USE',

  MAX_SUBAGENT_SUBACCOUNT_REACHED = 'MAX_SUBAGENT_SUBACCOUNT_REACHED',

  FEATURE_NOT_ENABLED = 'FEATURE_NOT_ENABLED',
  EXCEED_MIN_SHARE = 'EXCEED_MIN_SHARE',
  EXCEED_MAX_SHARE = 'EXCEED_MAX_SHARE',
  EXCEED_MIN_REBATE = 'EXCEED_MIN_REBATE',
  EXCEED_MAX_REBATE = 'EXCEED_MAX_REBATE',

  INVALID_BET_LIMIT = 'INVALID_BET_LIMIT',
  INSUFFICIENT_SHARES = 'INSUFFICIENT_SHARES',
  INVALID_REBATE = 'INVALID_REBATE',
  BALANCE_NOT_ZERO = 'BALANCE_NOT_ZERO',

  EXCEED_MAX_DATE_RANGE = 'EXCEED_MAX_DATE_RANGE',
}

export const notify = {
  success(args: NotifySuccess) {
    notification.success({
      message: args.message,
      placement: 'bottomLeft',
      description: args.description,
    });
  },

  error(args: NotifyError) {
    const { message, description, e, t } = args;

    let msg = message ? message : t('common:SomethingWentWrong');
    const desc = description ? description : e?.response?.data?.message;

    if (e?.response?.status == 429) {
      msg = t('common:error.ThrottlerException');
    } else if (Object.values(ApiError).includes(e?.response?.data?.message)) {
      msg = t(`common:error.${e.response.data.message}`);
    } else {
      datadogRum.addError(e, {
        response: e?.response?.data?.message,
      });
    }
    notification.error({
      message: msg,
      placement: 'bottomLeft',
      description: desc,
    });
  },
};
