import type { GameSiteSetting } from 'genteras-subagent/src/constant/Enums';
import { axiosBase, axiosPlayerBase } from '../utils/axiosHelpers';

type LoginData = {
  username: string;
  password: string;
  types: string[];
  recaptchaToken: string;
};

export type User = {
  id: string;
  email: string;
  username: string;
  preferredTimezone: number;
  playerWallet: PlayerWallet;
  overallStatus: string;
  unreadAnnouncementsCount: number;
  whitelabelClient: WhitelabelClient | null;
  passwordLastReset: Date;
  gameSiteSettings: GameSiteSetting[];
};

export enum WhitelabelClient {
  CASA_98 = 'CASA_98',
  KSTW_AGENT = 'KSTW_AGENT',
  KSCN_AGENT = 'KSCN_AGENT',
}

type ChangePasswordData = {
  password: string;
  currentPassword: string;
};

type ResetPasswordData = {
  password: string;
};

type UpdatePlayerData = {
  preferredTimezone?: number;
};

export type PlayerWallet = {
  balance: number;
  maxBalance: number;
};

export type UpdateLastNotifiedAtResponse = {
  id: string;
};

type LoginResponse = {
  id: string;
};
type ChangePasswordResponse = {
  status: boolean;
};
const UserAPI = {
  login: async (loginData: LoginData): Promise<LoginResponse> => {
    const response = await axiosBase.post(
      `/auth/login`,
      JSON.stringify(loginData)
    );
    const { data, status } = response;
    if (status >= 400) {
      throw new Error(data.message);
    }

    return response.data;
  },
  update: async (updateData: UpdatePlayerData): Promise<LoginResponse> => {
    const response = await axiosPlayerBase.patch(
      `/players/me`,
      JSON.stringify(updateData)
    );
    const { data, status } = response;
    if (status >= 400) {
      throw new Error(data.message);
    }

    return response.data;
  },
  changePassword: async (
    changePasswordData: ChangePasswordData
  ): Promise<ChangePasswordResponse> => {
    const response = await axiosPlayerBase.post(
      `/players/change-password`,
      JSON.stringify(changePasswordData)
    );
    const { data, status } = response;
    if (status >= 400) {
      throw new Error(data.message);
    }

    return response.data;
  },
  resetPassword: async (
    resetPasswordData: ResetPasswordData
  ): Promise<ChangePasswordResponse> => {
    const response = await axiosPlayerBase.post(
      `/players/reset-password`,
      JSON.stringify(resetPasswordData)
    );
    const { data, status } = response;
    if (status >= 400) {
      throw new Error(data.message);
    }

    return response.data;
  },
  logout: async (): Promise<LoginResponse> => {
    const response = await axiosBase.get(`/auth/logout`);
    const { data, status } = response;
    if (status >= 400) {
      throw new Error(data.message);
    }

    return response.data;
  },
  updateLastNotifiedAt: async (): Promise<UpdateLastNotifiedAtResponse> => {
    const response = await axiosPlayerBase.put(`/players/me/last-notified-at`);
    const { data, status } = response;
    if (status >= 400) {
      throw new Error(data.message);
    }

    return response.data;
  },
};

export default UserAPI;
