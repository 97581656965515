import { ExclamationCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import { commonConfig } from '../../lib/i18n/configs';

const ActionCtn = styled.div({
  flex: '0 0 auto',
  padding: '12px 40px',
  display: 'flex',
  ['> *']: {
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ['svg']: {
    color: 'red',
  },
});

const ConfirmDeleteDialog: FC = () => {
  const { t } = useTranslation(commonConfig.i18nNamespaces);

  return (
    <>
      <ActionCtn>
        <ExclamationCircleOutlined style={{ fontSize: '50px' }} />
      </ActionCtn>
      <br />
      <ActionCtn>
        <div> {t('common:FrozenMsgAlert1')}</div>
      </ActionCtn>
      <ActionCtn>
        <div> {t('common:FrozenMsgAlert2')}</div>
      </ActionCtn>
    </>
  );
};

export default ConfirmDeleteDialog;
