import useSWR from 'swr';

import type { AnnouncementsResponse } from '../api/announcement';
import fetcher from '../utils/fetcher';

const useAnnouncements = (pageIndex: number) => {
  const { data, error } = useSWR(`/announcements?page=${pageIndex}`, fetcher);

  return {
    announcements: (data || { data: [], meta: {} }) as AnnouncementsResponse,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useAnnouncements;
