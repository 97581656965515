import { Language } from '../language/Language';

export const getMainSiteUrl = (currentLanguage: string | undefined) => {
  const mainSiteUrl = process.env.NEXT_PUBLIC_MAIN_SITE_URL;

  return new URL(
    currentLanguage === Language.en_US ? '' : currentLanguage || '',
    mainSiteUrl
  ).href;
};
