import { ExclamationCircleFilled } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Tooltip } from 'antd';
import type { FC } from 'react';

const positiveTextColor = '#00a65a';
const negativeTextColor = 'red';

const PositiveAmount = styled.span({
  color: positiveTextColor,
});
const NegativeAmount = styled.span({
  color: negativeTextColor,
});

interface Prop {
  value: number | undefined;
  beforeValue?: number;
  className?: string;
  theme?: 'default' | 'initial';
  // No next-i18n in this package
  // eslint-disable-next-line
  t?: any;
}

const precision = 2;

export const formattedCurrency = (value: number, precision = 2) => {
  if (!value && value !== 0) return '';

  return formatMoney(value, precision);
};

export const FormattedCurrency: FC<Prop> = (props: Prop) => {
  const { value, className, theme, beforeValue, t } = props;

  if (!value && value !== 0) return <span></span>;

  const amount = formatMoney(value, precision);

  const Amount = () => {
    switch (theme) {
      case 'initial':
        return <span className={className}>{amount}</span>;
      case 'default':
      default:
        return value > 0 ? (
          <PositiveAmount className={className}>{amount}</PositiveAmount>
        ) : value < 0 ? (
          <NegativeAmount className={className}>{amount}</NegativeAmount>
        ) : (
          <span className={className}>{amount}</span>
        );
    }
  };

  const Remark = () => {
    if (!beforeValue || beforeValue == value) return null;

    const beforeAmount = formatMoney(beforeValue, precision);
    return (
      <Tooltip
        title={
          <>
            {t('PreAdjustedFigure', { amount: beforeAmount })}
            <br />
            {t('PostAdjustedFigure', { amount })}
          </>
        }
      >
        <ExclamationCircleFilled style={{ color: '#faad14' }} />
      </Tooltip>
    );
  };

  return (
    <>
      <Amount /> <Remark />
    </>
  );
};

export const formatMoney = (num: number, fractionDigits?: number) => {
  return Number(num.toFixed(9)).toLocaleString('en', {
    minimumFractionDigits: fractionDigits,
  });
};
