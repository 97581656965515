import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import type { FC } from 'react';

dayjs.extend(utc);
interface Prop {
  value: Date | Dayjs;
  className?: string;
  utcOffsetHours?: number | string;
}

const datetimeFormat = 'YYYY/MM/DD HH:mm:ss';

export const FormattedDateTime: FC<Prop> = (props: Prop) => {
  const { value, className, utcOffsetHours } = props;

  if (!value) return <span></span>;

  const noTimezone = utcOffsetHours === null || utcOffsetHours === undefined;

  if (noTimezone) {
    const timezoneVal = new Date().getTimezoneOffset() / -60;
    return (
      <span className={className}>
        {dayjs(value)
          .utcOffset(timezoneVal * 60)
          .format(datetimeFormat)}
      </span>
    );
  }

  return (
    <span className={className}>
      {dayjs(value).utcOffset(utcOffsetHours).format(datetimeFormat)}
    </span>
  );
};
