import axios from 'axios';
import { AppConfig } from './AppConfig';

axios.defaults.timeout = 30000;
export const axiosBase = axios.create({
  baseURL: AppConfig.apiBaseUrl,
  headers: { 'Content-Type': 'application/json' },
});

export const axiosPlayerBase = axios.create({
  withCredentials: true,
  baseURL: AppConfig.playerApiBaseUrl,
  headers: { 'Content-Type': 'application/json' },
});
