import styled from '@emotion/styled';
import { FormattedCurrency } from '@genteras/ui-lib/base';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import { commonConfig } from '@/lib/i18n/configs';
import PointSvg from '@/public/images/icon/point.svg';
import useUser from '../../lib/hooks/useUser';

export const UserAmount: FC = () => {
  const { t } = useTranslation(commonConfig.i18nNamespaces);
  const { user } = useUser();

  const breakpoints = [792];

  const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

  const AmountDiv = styled.div({
    paddingTop: '0px',
    height: '30px',
    borderRadius: '15px 15px',
    width: 300,
    display: 'flex',
    alignItems: 'center',
    border: '1px solid var(--White)',
  });

  return (
    <AmountDiv
      css={{
        display: 'flex !important',
        [mq[0]]: { display: 'none !important' },
      }}
      className={'user-amount'}
    >
      <PointSvg
        className={'user-amount-icon'}
        css={{
          marginLeft: '10px',
          marginRight: '5px',
          width: '25px',
          height: '25px',
          color: 'var(--White)',
        }}
      />
      <span style={{ color: 'var(--White)' }}>
        <FormattedCurrency
          value={user.playerWallet.balance}
          theme="initial"
          css={
            user.playerWallet.maxBalance === 0 || // 0 is unlimited
            user.playerWallet.balance < user.playerWallet.maxBalance
              ? { color: 'var(--White)' }
              : { color: 'red' }
          }
          className={'user-amount-number'}
        />
        /
        {user.playerWallet.maxBalance === 0 ? ( // 0 is unlimited
          t('common:Unlimited')
        ) : (
          <FormattedCurrency
            value={user.playerWallet.maxBalance}
            theme="initial"
          />
        )}
      </span>
    </AmountDiv>
  );
};
