import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import config, { Sites } from '../../config';
import type { SiteConfig } from '../../config';

export const useSiteConfig = () => {
  const { locale } = useRouter();
  const [siteConfig, setSiteConfig] = useState<SiteConfig>({
    siteName_i18n: {},
    siteName: '',
    logoHeader: '',
    logoCompact: '',
    favicon: '',
    systemIcon: '',
  });

  useEffect(() => {
    const _config =
      config.sitesConfig.get(window.location.hostname as Sites) ||
      config.sitesConfig.get(Sites.DEFAULT)!;
    setSiteConfig({
      ..._config,
      siteName: locale ? _config.siteName_i18n[locale] : '',
    });
  }, []);

  return siteConfig;
};
